import React from 'react'
import { Header, Icon, List, Message, Placeholder } from 'semantic-ui-react'
import ResourceCard from './resource_card'
import Link from './link'

export default function DomainHostingServiceCard({ loading, value }) {
	loading = loading ?? false
	value = value ?? null

	let content = <ResourceCard.Content>Skriv inn et domenenavn...</ResourceCard.Content>
	if (loading) {
		content = <ResourceCard.Content>
			<Placeholder>
				<Placeholder.Line />
			</Placeholder>
		</ResourceCard.Content>
	} else if (typeof value === 'object' && Array.isArray(value)) {
		content = <ResourceCard.Content>
			{value.map((host, i) => <React.Fragment key={'hosting_host_' + i}>
				<Header size="small">
					{host.name}
					{typeof host.admin_url === 'string' && host.admin_url.length > 0 ? <>
						{' '}
						<Link
							href={host.admin_url}
							rel="noopener nofollow noreferrer"
							target="_blank"
						>🔗</Link>
					</> : null}
					<Header.Subheader>
						<code>{host.source}</code>
					</Header.Subheader>
				</Header>
				{host.type === 'kit_redir' && host.redirect ? <p>Videresending til <Link href={host.redirect.url} rel="noopener nofollow noreferrer" target="_blank"><Icon name="external" />&ensp;{host.redirect.domain ?? host.redirect.url}</Link></p> : null}
				<List bulleted>
					{host.hosts.map((hostname, j) => <List.Item key={'hosting_host_' + i + '_hostname_' + j}>
						<code className="text-overflow-visible text-break-word">{hostname}</code>
						{' '}
						<Link
							href={'https://bgp.tools/search?q=' + encodeURIComponent(hostname)}
							rel="noopener nofollow noreferrer"
							target="_blank"
						>
							🔎
						</Link>
					</List.Item>).concat(host.ips.map((ip, j) => <List.Item
						key={'hosting_host_' + i + '_ip_' + j}
					>
						<code className="text-overflow-visible text-break-word">{ip}</code>
						{' '}
						<Link
							href={'https://bgp.tools/search?q=' + encodeURIComponent(ip)}
							rel="noopener nofollow noreferrer"
							target="_blank"
						>
							🔎
						</Link>
					</List.Item>))}
				</List>
			</React.Fragment>)}
		</ResourceCard.Content>
	} else {
		content = <ResourceCard.Content>
			<Message error content="Fant ingen hosting-tjeneste på domenet." />
		</ResourceCard.Content>
	}

	return <ResourceCard
		icon="server"
		title="Hosting-tjeneste"
	>
		{content}
	</ResourceCard>
}